<template>
  <div>
    <userForm></userForm>
  </div>
</template>

<script>
import userForm from '../components/userForm'
  export default {
    components: {userForm},
    mounted(){
      this.$store.state.editedProfile = true
    },
    beforeDestroy(){
      this.$store.state.editedProfile = false
    }
  }
</script>

<style lang="scss" scoped>

</style>