<template>
  <div>
    <v-snackbar
      v-model="$store.state.userForm.snackbar"
      :timeout="$store.state.userForm.timeout"
      top
    >
      Salvo com sucesso <v-icon color="green">mdi-check-bold</v-icon>
    </v-snackbar>
    <v-form ref="form" v-model="$store.state.userForm.valid">
      <v-card :loading="$store.state.userForm.loading" width="100vh">
        <v-card-title>
          <span class="headline">{{ title }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <div>
              <v-row>
                <v-col offset-md="4"><avatar></avatar></v-col>
              </v-row>

              <v-row>
                <v-text-field
                  v-model="$store.state.user.name"
                  label="Nome"
                  :rules="$store.state.userForm.rulesName"
                ></v-text-field>
              </v-row>

              <v-row>
                <v-text-field
                  v-model="$store.state.user.email"
                  label="Email"
                  :rules="$store.state.userForm.rulesEmail"
                ></v-text-field>
              </v-row>

              <v-row>
                <v-text-field
                  v-model="$store.state.user.source"
                  label="Link do site"
                ></v-text-field>
              </v-row>
<!-- COLORS -->
              <v-row >
                <v-menu
                  right
                  :offset-x="true"
                  v-model="menu"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      :color="$store.state.user.color"
                      elevation="2"
                      dark
                      @click="showColors = !showColors"
                      >Cor</v-btn
                    >
                  </template>
                  <!-- <v-color-picker
                v-model="$store.state.editedUser.color"
                dot-size="25"
                hide-inputs
                show-swatches
              ></v-color-picker> -->

                  <v-list>
                    <v-list-item color="#fb611c">
                      <v-btn
                        @click="$store.state.user.color = '#fb611c'"
                        color="#fb611c"
                        dark
                        >#fb611c</v-btn
                      >
                    </v-list-item>
                    <v-list-item color="#fdad01">
                      <v-btn
                        color="#fdad01"
                        @click="$store.state.user.color = '#fdad01'"
                        dark
                        >#fdad01</v-btn
                      >
                    </v-list-item>
                    <v-list-item color="#0061a7">
                      <v-btn
                        color="#0061a7"
                        @click="$store.state.user.color = '#0061a7'"
                        dark
                        >#0061a7</v-btn
                      >
                    </v-list-item>
                    <v-list-item color="#000000">
                      <v-btn
                        color="#000000"
                        @click="$store.state.user.color = '#000000'"
                        dark
                        >#000000</v-btn
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-row>

              <v-row>
                <v-select
                  disabled
                  :items="$store.state.accountTypes"
                  v-model="$store.state.user.type"
                  label="Tipo de conta"
                  :rules="$store.state.userForm.rulesType"
                ></v-select>
              </v-row>

              <v-row>
                <v-select
                  disabled
                  chips
                  multiple
                  :items="$store.state.routes"
                  v-model="$store.state.user.descriptions"
                  label="Acessos"
                  item-text="description"
                ></v-select>
              </v-row>
            </div>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="blue darken-1"
            :loading="$store.state.userForm.loading"
            @click="save()"
            text
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import avatar from "../components/userTable/Avatar";
//getUsers
import { updateUser } from "../services/users";
export default {
  components: { avatar },
  data: () => ({
    showColors: false,
    menu: false
  }),
  computed: {
    title() {
      return "Usuário: " + this.$store.state.user.email;
    },
  },
  methods: {
    validateFields() {
      this.$store.state.userForm.valid = this.$refs.form.validate();
      const isEqual =
        this.$store.state.userJson == JSON.stringify(this.$store.state.user);
      this.$store.state.userJson = JSON.stringify(this.$store.state.user);
      this.$store.state.userForm.valid = isEqual
        ? false
        : this.$store.state.userForm.valid;
    },
    // selectSimple(data) {
    //   this.$store.state.user.descriptions = this.$store.state.routes.filter(
    //     (route) => route.accessGroup.includes(data)
    //   );
    //   this.$store.state.user.routes = this.$store.state.user.descriptions;
    //   this.$store.state.user.totalRoutes = (
    //     this.$store.state.descriptions || []
    //   ).length;
    // },
    // selectedMultiple(data) {
    //   const dataField = data.reduce(
    //     (acc, item) => [
    //       ...acc,
    //       ...this.$store.state.routes.filter(
    //         (data) => data.description == item
    //       ),
    //     ],
    //     []
    //   );
    //   this.$store.state.user.routes = dataField;
    //   this.$store.state.user.totalRoutes = (dataField || []).length;
    // },
    async save() {
      this.$store.state.userForm.loading = true;
      this.validateFields();
      if (!this.$store.state.userForm.valid)
        return (this.$store.state.userForm.loading = false);
      await updateUser(this.$store.state.user);
      this.$store.state.userForm.snackbar = true;
      this.$store.state.userForm.loading = false;
      this.$store.state.userForm.valid = false;
    },
  },
};
</script>