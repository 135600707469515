import axios from 'axios'
import baseUrl from './baseUrl'
import store from '../store'
const errors = err => {
  console.error(err.response ? err.response.data.stack : err.stack)
  if (err.response && err.response.status == 401) {
    //window.location.href = "/login"
  }
  return Promise.reject(err.response ? err.response.data.message : 'Houve uma falha na comunição, verifique sua internet e tente novamente')
}
const headers = { token: localStorage.getItem('token') }
let users = axios.create({ baseURL: baseUrl.tnAdmin, headers })

export const login = (data) => users.post('/users/login', data).then(({ data }) => {
  localStorage.setItem('token', data.jwt)
  headers.token = data.jwt
  store.state.user = { ...store.state.user, ...data }
  users = axios.create({ baseURL: baseUrl.tnAdmin, headers })
  return data
})
  .catch(errors)

export const getUser = () => users.get('/users/user').then(({ data }) => data)
  .catch(errors)

export const getUsers = () => users.get('/users').then(({ data }) => data)
  .catch(errors)

export const getPartners = () => users.get('/users/partners').then(({ data }) => data)
.catch(errors)


export const createUser = user => users.post('/users', user).then(({ data }) => data.message).catch(errors)

export const deleteUser = email => users.delete('/users/user/' + email).then(data => data.data.message).catch(errors)

export const forgotPassword = (email) => users.get('/users/forgot-password/' + email).then(({ data }) => data.message)
  .catch(errors)

export const alterPassword = (password, confirmPassword) => {
  users = axios.create({ baseURL: baseUrl.tnAdmin, headers: { token: localStorage.token } })
  return !(password && confirmPassword && (password == confirmPassword))
    ? errors({ response: { data: 'Verifique os campos senha e confirmar senha' } })
    : users.put('/users/password', { password, confirmPassword })
      .then(({ data }) => data.message)
      .catch(errors)
}

export const updateUser = (user) => users.put('/users/user', user).then(({ data }) => data.message).catch(errors)