<template>
<div style="cursor: pointer">
  <label for="file" style="cursor:pointer">
  <v-avatar color="grey lighten-2" size="128" tile >
    <div v-if="!logo">Logo</div>
    <img v-else :src="logo" alt="Logo" />
  </v-avatar>
  </label>
  <input type="file" id="file" name="file" ref="file" accept="image/*" @change="loadFile" style="display: none"/>
</div>
</template>

<script>
import { sendImageLogo } from '../../services/repository';
export default {
  computed:{
    logo(){
      if(this.$store.state.editedProfile){
        return this.$store.state.user.logo
      }
      return this.$store.state.editedUser.logo
    }
  },
  methods: {
    async loadFile(file) {
      if(file.target.files[0]){
        const url = await sendImageLogo(file.target.files[0])
        if(this.$store.state.editedProfile){
          this.$store.state.user.logo = url.url
        }else{
          this.$store.state.editedUser.logo = url.url
        }
       
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>